var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('div',{staticClass:"video-grid fill-parent"},[_c('div',{staticClass:"player"},[(_vm.$store.getters.loading)?[_c('v-skeleton-loader',{attrs:{"tile":"","elevation":"0","type":"image","height":"100%","width":"100%"}})]:_vm._e(),(_vm.$store.getters['video/player/rendering'])?_c('v-card',{class:_vm.$store.getters['video/player/hidden'] ? 'hidden' : '',attrs:{"tile":"","flat":"","height":"100%","width":"100%"}},[_c('Player',{key:_vm.playerKey,ref:"player",attrs:{"start":_vm.start},on:{"current-time-change":_vm.currentTimeChange,"invalid-video":function($event){return _vm.invalidVideo($event)}}})],1):_vm._e()],2),_c('div',{staticClass:"section-table"},[_c('v-card',{staticClass:"fill-parent",attrs:{"tile":""}},[_c('div',{staticClass:"section-table-grid fill-parent"},[_c('div',{staticClass:"section-table-title"},[(_vm.$store.getters.loading)?[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"card-heading","width":"150px"}})],1)],1)]:[_c('v-card-title',[_vm._v(_vm._s(_vm.$tc("section", 2)))])]],2),_c('div',{staticClass:"section-table-content"},[(_vm.$store.getters.loading)?[_c('div',{staticClass:"fill-parent align-center",staticStyle:{"padding-left":"6px","padding-right":"6px"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"10"}},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)],1)],1)]:[(_vm.$store.getters.isEditMode)?[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"justify-center",attrs:{"icon":"","width":"100%","disabled":_vm.$store.getters['video/currentTime']
                              .fullSeconds ===
                            _vm.$store.getters['video/duration'].fullSeconds},on:{"click":_vm.addCurrentSection}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1268471244)},[_c('span',[_vm._v(_vm._s(_vm.$t("newSection")))])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"justify-center",attrs:{"icon":"","width":"100%"},on:{"click":function($event){_vm.showIndex = !_vm.showIndex}}},on),[(_vm.showIndex)?_c('v-icon',[_vm._v(" mdi-sort-variant-remove ")]):_c('v-icon',[_vm._v(" mdi-sort-variant ")])],1)]}}],null,false,1096225199)},[(_vm.showIndex)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("hideIndices"))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.$t("showIndices"))+" ")])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"justify-center",attrs:{"icon":"","disabled":_vm.splitSectionDisabled,"width":"100%"},on:{"click":function($event){return _vm.$store.dispatch('splitSectionWithHistory')}}},on),[_c('v-icon',[_vm._v("mdi-arrow-split-vertical")])],1)]}}],null,false,3197141090)},[_c('span',[_vm._v(_vm._s(_vm.$t("splitCurrentSection")))])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"justify-center",attrs:{"icon":"","width":"100%","disabled":!_vm.$store.getters['history/isUndo']},on:{"click":function($event){return _vm.$store.dispatch('undo')}}},on),[_c('v-icon',[_vm._v("mdi-undo")])],1)]}}],null,false,4049250456)},[_c('span',[_vm._v(_vm._s(_vm.undoTooltip))])])],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"justify-center",attrs:{"icon":"","width":"100%","disabled":!_vm.$store.getters['history/isRedo']},on:{"click":function($event){return _vm.$store.dispatch('redo')}}},on),[_c('v-icon',[_vm._v("mdi-redo")])],1)]}}],null,false,168466612)},[_c('span',[_vm._v(_vm._s(_vm.redoTooltip))])])],1)],1)]:_vm._e(),_c('v-data-table',{attrs:{"height":_vm.sectionHeight,"items":_vm.sectionTableItems,"headers":_vm.sectionTableHeaders,"no-data-text":_vm.$t('noSections'),"fixed-header":"","disable-pagination":"","hide-default-footer":"","disable-sort":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                            var item = ref.item;
return [_c('tr',{class:_vm.$store.getters['video/sections/index'] === item.index
                        ? ("highlight-section-" + (_vm.$vuetify.theme.dark ? 'dark' : 'light'))
                        : ''},[(_vm.sectionTableColumnsShow[0])?[_c('td',{staticStyle:{"padding":"0"}},[(
                            _vm.$store.getters['video/sections/index'] !==
                            item.index
                          )?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.playSection(item.index)}}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-play")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("play")))])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                          var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$store.commit('video/sections/index', -1)}}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-selection-off")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("deselect")))])])]],2)]:_vm._e(),(_vm.sectionTableColumnsShow[1])?[_c('td',[_c('IndexMenu',{attrs:{"index":item.index}})],1)]:_vm._e(),(_vm.$store.getters.isPlayMode)?[_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.section.start.toString())+" ")]),_c('td',{staticClass:"text-right"},[_vm._v(" "+_vm._s(item.section.end.toString())+" ")])]:(_vm.$store.getters.isEditMode)?[_c('td',{staticClass:"text-right"},[_c('TimestampMenu',{attrs:{"type":_vm.$t('editSectionStart'),"initial":item.section.start,"max":{
                            type: 'sectionEnd',
                            value: item.section.end,
                          }},on:{"save":function($event){return _vm.$store.dispatch('updateSectionStartWithHistory', {
                              index: item.index,
                              section: {
                                start: $event,
                                end: item.section.end,
                              },
                            })}}})],1),_c('td',{staticClass:"text-right"},[_c('TimestampMenu',{attrs:{"type":_vm.$t('editSectionEnd'),"initial":item.section.end,"min":{
                            type: 'sectionStart',
                            value: item.section.start,
                          },"max":{
                            type: 'sectionEnd',
                            value: _vm.durationBound,
                          }},on:{"save":function($event){return _vm.$store.dispatch('updateSectionEndWithHistory', {
                              index: item.index,
                              section: {
                                start: item.section.start,
                                end: $event,
                              },
                            })}}})],1),_c('td',{staticStyle:{"padding":"0"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                            var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.$store.dispatch(
                                  'removeSectionWithHistory',
                                  item.index
                                )}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("delete")))])])],1)]:_vm._e()],2)]}}])})]],2)])])],1),_c('div',{staticClass:"player-control"},[_c('PlayerControl',{ref:"playerControl"})],1)]),_c('import-project-dialog',{ref:"importDialog",on:{"rerender-player":_vm.rerenderPlayer,"close-error-snackbar":_vm.closeErrorSnackbar}}),_c('v-snackbar',{attrs:{"text":"","timeout":"6000","color":"error","top":""},model:{value:(_vm.errorSnackbar),callback:function ($$v) {_vm.errorSnackbar=$$v},expression:"errorSnackbar"}},[_c('div',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.errorSnackbarMessage))])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }