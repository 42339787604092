var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"tile":"","height":"100%","width":"100%"}},[_c('v-card-actions',[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"2"}}),_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"8"}},[(_vm.$store.getters.loading)?[_c('v-skeleton-loader',{attrs:{"type":"avatar"}})]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.noPreviousSection},on:{"click":_vm.previousSection}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("mdi-skip-previous")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("playPreviousSection")))])]),(_vm.isPlay)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":_vm.$store.getters.color[
                        _vm.$vuetify.theme.dark ? 'dark' : 'light'
                      ]},on:{"click":function($event){return _vm.$store.getters['video/player/player'].playVideo()}}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-play")])],1)]}}],null,false,943013980)},[_vm._v(" "+_vm._s(_vm.$t("play"))+" ")]):(_vm.isPause)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":_vm.$store.getters.color[
                        _vm.$vuetify.theme.dark ? 'dark' : 'light'
                      ]},on:{"click":function($event){return _vm.$store.getters['video/player/player'].pauseVideo()}}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pause")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("pause"))+" ")]):(_vm.isRestart)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var tooltip = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":_vm.$store.getters.color[
                        _vm.$vuetify.theme.dark ? 'dark' : 'light'
                      ]},on:{"click":_vm.restart}},Object.assign({}, tooltip)),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-restart")])],1)]}}])},[_vm._v(" "+_vm._s(_vm.$t("restart"))+" ")]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","disabled":_vm.noNextSection},on:{"click":_vm.nextSection}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("mdi-skip-next")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("playNextSection")))])])]],2),_c('v-col',{staticClass:"d-flex justify-end align-center",attrs:{"cols":"2"}},[(!_vm.$store.getters.loading)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var tooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":_vm.showShareDialog}},Object.assign({}, tooltip)),[_c('v-icon',[_vm._v("mdi-share")])],1)]}}],null,false,24109562)},[_c('span',[_vm._v(_vm._s(_vm.$t("share")))])])]:_vm._e()],2)],1),(_vm.$store.getters.loading)?_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"1"}},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1),_c('v-col',{attrs:{"cols":"10"}},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-skeleton-loader',{attrs:{"type":"text"}})],1)],1):_c('v-row',{attrs:{"dense":""}},[_c('v-col',[_c('v-slider',{staticClass:"align-center",attrs:{"hide-details":"","color":_vm.$store.getters.color[_vm.$vuetify.theme.dark ? 'dark' : 'light'],"min":_vm.$store.getters['video/currentSectionStart'].fullSeconds,"max":_vm.$store.getters['video/currentSectionEnd'].fullSeconds},on:{"click":_vm.onClick,"start":_vm.onDragStart,"end":_vm.onDragEnd},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('div',[_vm._v(_vm._s(_vm.currentTime))])]},proxy:true},{key:"append",fn:function(){return [_c('div',[_vm._v("-"+_vm._s(_vm.timeLeft))])]},proxy:true}]),model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}})],1)],1)],1)],1)],1),_c('share-project-dialog',{ref:"shareDialog"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }